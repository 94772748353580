body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.read-more-link{
  color: blueviolet;
  text-decoration: underline;
  letter-spacing: 1px;
  cursor: pointer;
}
.extra-content{
  color: cornflowerblue;
  font-weight: 500;
}

body {
	background:#fff;
  height:100%;
}

a.border.nav-link.border-light.rounded.mr-1 {
  color: #3F729B;
}

a.border.nav-link.border-light.rounded.mr-1:hover {
  color: #1C2331;
}

.btn.btn-sm.indigo:hover{
  color: #fff;
}

.nav-link.active{
  background-color: rgba(255, 255, 255, 0.1);
}


#categories > .col-md-4.mb-5{
  margin-bottom: 8rem !important;
}
.sectionPage {
  position: relative;
  top: -15px;
  margin-bottom: -125px;
}

.sectionPage::after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  /*background: url('https://mdbootstrap.com/wp-content/uploads/2016/12/big-bundle1.jpg');*/
  background-position: center center;
  background-size: cover;
  filter: opacity(.1);
}
#footer {
position: relative;
            padding: 10px 10px 0px 10px;
            bottom: 0;
            width: 100%;
            /* Height of the footer*/ 
}

#tablebody{
  min-height: 800px;
}

.cells{
  border:1px solid #eeeeee; 
  background-color:#fffaaa;
  padding: 3px;
  font-family: "Arial";
}
.cells:hover {
    background-color:#ffffff;
}

#country{
position:absolute; left:73px;
margin-top:17px;
border:none;
background-color: white;


}
#companylogo{
border:1px solid #eeeeee;

}

#companylink{
  border:1px solid #eeeeee;
width:100px;
overflow:visible;
}
#tablehead{
  position:fixed;
}